<template>
  <CardConf
    :title="$t('qr')"
    :icon="'mdi-qrcode'"
    :keys="'qr_active'"
    :activeted="false"
    ref="qrCard"
    @edit="edit"
    @save="saveLinks"
    :buttons="true"
  >
    <template>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-card>
            <form @submit="saveLinks">
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <span>
                      {{ $t("edit_text_link") }}
                    </span>
                  </v-col>
                </v-row>
                <v-row
                  v-for="(other_link, i) in links.other_links"
                  :key="i"
                  align="center"
                >
                  <v-col class="py-0">
                    <label>Nombre</label>
                    <v-text-field
                      outlined
                      :disabled="disabled"
                      hide-details
                      dense
                      v-model="other_link.name"
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0">
                    <label>Enlace</label>
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      :disabled="disabled"
                      v-model="other_link.http"
                    ></v-text-field>
                  </v-col>
                  <v-btn
                    icon
                    @click="deleteOtherLink(i)"
                    :disabled="disabled"
                    class="mt-6"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        x-small
                        fab
                        :disabled="disabled"
                        outlined
                        style="margin-left: -10px"
                        @click="addOtherLink"
                        v-on="on"
                        class="mt-3"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn></template
                    >
                    {{ $t("add", { name: "link" }) }}
                  </v-tooltip>
                  <v-spacer></v-spacer>
                  <v-col cols="1"></v-col>
                </v-row>
              </v-card-text>
              <v-card-actions> </v-card-actions>
            </form>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="">
            <v-card-text>
              <v-img class="mx-auto" max-width="60%" :src="qr.url"></v-img>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    color="primary"
                    @click="downloadItem()"
                    v-on="on"
                    small
                    :disabled="disabled"
                    style="position: absolute; top: 5px; right: 5px"
                  >
                    <v-icon small>$download</v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t("download_qr") }}
                </span>
              </v-tooltip>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </CardConf>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
  name: "AlertsCard",
  components: {
    CardConf: () => import("@/components/user/settings/Card"),
  },
  mounted() {
    this.fetchLinks();
    this.fetchQR();
  },
  methods: {
    ...mapActions("users", ["settingUpdate", "getSetting"]),
    edit(valor) {
      this.disabled = valor;
      //console.log(valor);
      if (valor) {
        //console.log("reset");
        this.fetchLinks();
      }
    },
    ...mapActions("studios", ["redirectionLinks", "getQR"]),
    fetchLinks() {
      this.redirectionLinks(this.$store.state.auth.user.studio.token).then(
        (data) => {
          this.links = data.links;
        }
      );
    },
    fetchQR() {
      this.getQR().then((data) => {
        this.qr = data;
      });
    },
    addOtherLink() {
      this.links.other_links.push({});
    },
    deleteOtherLink(index) {
      this.$confirm("¿Seguro que quieres eliminar?", "", "").then(() => {
        this.links.other_links.splice(index, 1);
      });
    },
    saveLinks() {
      this.settingUpdate({
        key: "qr_links",
        value: this.links,
        json: true,
      }).then((data) => {
        //console.log(data);
        this.fetchLinks();
      });
    },
    downloadItem() {
      axios
        .get(this.qr.url, { responseType: "blob" })
        .then((response) => {
          //console.log("return");
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "qr.png";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
  },
  data() {
    return {
      disabled: true,
      links: {
        inscription: false,
        other_links: [{ http: null, name: null }],
      },
      qr: null,
      old_links: {},
    };
  },
  computed: {},
};
</script>

